import { UserStatusChangeConfirmationModal } from 'app/pages/admin/users/components/UserStatusChangeConfirmationModal';
import { useFormikContext } from 'formik';
import { IFetchUsersProfileResponse } from 'lib/api/users/useUsersProfileQuery';
import { ButtonSwitch } from 'lib/components';
import { ModalContactAM } from 'lib/components/modal/ModalContactAM';
import { Flex } from 'lib/components/styles/layout';
import {
  ParagraphNormal,
  ParagraphNormalBold,
  ParagraphSmall,
} from 'lib/components/styles/typography';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Tooltip } from 'react-covideo-common';
import { MdInfo, MdKeyboardArrowRight, MdSave } from 'react-icons/md';
import { useIsFetching, useIsMutating } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

export const ManageUsersHeader = ({
  showActionButtons = true,
  currentUser,
  isExceeded,
}: {
  showActionButtons?: boolean;
  isExceeded?: boolean;
  currentUser: IFetchUsersProfileResponse | undefined;
}) => {
  const views = [
    {
      value: 'enabled',
      text: 'Active',
    },
    {
      value: 'disabled',
      text: 'Inactive',
    },
  ];
  const { colors } = useTheme();
  const history = useHistory();
  const fetching = useIsFetching();
  const mutating = useIsMutating();
  const { id } = useParams() as { id: string | undefined };
  const { isValid, dirty } = useFormikContext<any>();
  const [showAMModal, setShowAMModal] = useState(false);
  const [stickyHeader, setStickyHeader] = useState(false);
  const [newStatus, setNewStatus] = React.useState<any>(undefined);
  const [showStatusModal, setShowStatusModal] = React.useState(false);

  const handleScroll = useCallback(() => {
    setStickyHeader(window.pageYOffset > 64);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onGoToListUsersHandler = () => {
    return history.push('/users/list');
  };

  const stickyStyle: React.CSSProperties = {
    background: 'white',
    position: 'fixed',
    zIndex: 99,
    top: 64,
    left: 0,
    right: 0,
    width: '100%',
    marginTop: 0,
    padding: '12px 0',
    boxShadow: '0px 4px 12px rgba(29, 30, 36, 0.04)',
  };

  const contentStyle: React.CSSProperties = {
    maxWidth: 1248,
    margin: '0 auto',
  };

  const userHeaderInfo = id
    ? `${currentUser?.firstName} ${currentUser?.lastName}`
    : 'New User';

  return (
    <>
      <Flex flexDirection='column' gap='16px' margin='0 0 32px 0'>
        <Flex flexDirection='row' alignItems='center'>
          <ParagraphNormal
            onClick={onGoToListUsersHandler}
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            Manage Users
          </ParagraphNormal>
          <MdKeyboardArrowRight size={24} />
          <ParagraphNormal>{userHeaderInfo}</ParagraphNormal>
        </Flex>
        <div style={stickyHeader ? stickyStyle : {}}>
          <Flex
            width='100%'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            style={stickyHeader ? contentStyle : {}}
          >
            <Flex
              flexDirection='row'
              gap='32px'
              alignItems='center'
              width='auto'
            >
              <Flex flexDirection='row' gap='4px' alignItems='center'>
                <ParagraphNormalBold
                  style={{ fontSize: 24, display: 'flex', gap: '4px' }}
                >
                  {userHeaderInfo}
                </ParagraphNormalBold>
                {isExceeded && !id && (
                  <Tooltip
                    position={'right'}
                    extendStyles={{
                      popup: {
                        width: '310px',
                        fontSize: '16px',
                        lineHeight: '24px',
                        padding: '8px 12px',
                        fontWeight: '400',
                      },
                      element: {
                        cursor: 'pointer',
                      },
                    }}
                    popup={
                      <ParagraphSmall color={colors.neutral[80]}>
                        All your active user seats have been used. You can now
                        only create a Supervisor user. To add more user roles,
                        please{' '}
                        <span
                          onClick={() => setShowAMModal(true)}
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                        >
                          contact support
                        </span>
                        .
                      </ParagraphSmall>
                    }
                  >
                    <MdInfo color={'red'} size={18} />
                  </Tooltip>
                )}
              </Flex>

              {id && (
                <ButtonSwitch
                  key={!!currentUser?.verified ? 'enabled' : 'disabled'}
                  defaultValue={
                    !!currentUser?.verified ? 'enabled' : 'disabled'
                  }
                  skipConfirmation={false}
                  values={views}
                  onChange={newView => {
                    setNewStatus(newView);
                    setShowStatusModal(true);
                  }}
                  disabled={!!fetching}
                />
              )}
            </Flex>

            {showActionButtons && (
              <Flex width='auto' flexDirection='row' gap='8px'>
                <Button
                  variant='secondary'
                  text='Cancel'
                  onClick={onGoToListUsersHandler}
                  disabled={!!mutating}
                />
                <Button
                  icon={<MdSave size={18} />}
                  text={!!id ? 'Save Changes' : 'Add User'}
                  type='submit'
                  disabled={!isValid || !!mutating || !dirty}
                />
              </Flex>
            )}
          </Flex>
        </div>
      </Flex>
      {showStatusModal && (
        <UserStatusChangeConfirmationModal
          newStatus={
            newStatus === 'enabled'
              ? VerificationStatus.ACTIVE
              : VerificationStatus.INACTIVE ?? VerificationStatus.ACTIVE
          }
          handleModalClose={() => {
            setShowStatusModal(false);
          }}
          user={{
            id: currentUser?.id,
            firstName: currentUser?.firstName,
            lastName: currentUser?.lastName,
          }}
        />
      )}

      {showAMModal && (
        <ModalContactAM
          handleModalClose={() => setShowAMModal(false)}
          subject='Covideo Legacy Plan Update'
          title='Would you like to manage your subscription?'
          description={
            <div>
              To make changes to your subscription, please contact your Covideo
              account manager or fill out the form below.
            </div>
          }
        />
      )}
    </>
  );
};
